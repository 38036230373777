import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { plugin, defaultConfig } from '@formkit/vue';

library.add(faPhone);
library.add(faEnvelope);
library.add(faFacebook);
library.add(faFacebookF);
library.add(faInstagram);

createApp(App)
  .use(store)
  .use(router)
  .use(plugin, defaultConfig)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app');
