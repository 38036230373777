<template>
  <div class="container">
    <div class="title">
      <h1 class="heading"><span>Portfolio</span></h1>
    </div>
    <ul class="categories">
      <li>
        <button
          type="button"
          :class="{ 'is-active': state.currentCategory === 'all' }"
          @click="filter('all')"
        >
          All
        </button>
      </li>
      <li>
        <button
          type="button"
          :class="{ 'is-active': state.currentCategory === 'music' }"
          @click="filter('music')"
        >
          Music videos
        </button>
      </li>
      <li>
        <button
          type="button"
          :class="{ 'is-active': state.currentCategory === 'weddings' }"
          @click="filter('weddings')"
        >
          Weddings
        </button>
      </li>
      <li>
        <button
          type="button"
          :class="{ 'is-active': state.currentCategory === 'comercials' }"
          @click="filter('comercials')"
        >
          Comercials
        </button>
      </li>
      <li>
        <button
          type="button"
          :class="{ 'is-active': state.currentCategory === 'reels' }"
          @click="filter('reels')"
        >
          Reels
        </button>
      </li>
      <li>
        <button
          type="button"
          :class="{ 'is-active': state.currentCategory === 'bts' }"
          @click="filter('bts')"
        >
          Bts
        </button>
      </li>
    </ul>
    <div class="content">
      <spinner v-if="loading" />
      <ul class="gallery" v-if="!loading">
        <li
          v-for="video in state.videos"
          :key="video"
          @click="(selectedVideoId = video.id), (isModalOpen = true)"
          @touchstart="touchStart($event)"
          @touchend="touchEnd($event)"
        >
          <img :src="video.thumbnail" alt="" />
          <div class="overlay">
            <span>{{ video.title }}</span>
          </div>
        </li>
      </ul>
      <player-modal v-if="isModalOpen" :videoId="selectedVideoId" @close="isModalOpen = false" />
      <div class="pagination" v-if="state.pagesNumber > 1 && !loading">
        <a role="button" v-if="state.previousBtn" @click="previous">«</a>
        <li v-for="index in state.pagesNumber" :key="index">
          <a role="button" class="page" :class="{ 'is-active': index === state.currentPage }">{{
            index
          }}</a>
        </li>
        <a role="button" v-if="state.nextBtn" @click="next">»</a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue';
import PlayerModal from '@/components/PlayerModal.vue';
import Spinner from '@/components/Spinner.vue';
import {
  query,
  collection,
  getDocs,
  limit,
  startAfter,
  endBefore,
  limitToLast,
  orderBy,
  where,
} from 'firebase/firestore';
import db from '@/firebase/init.js';

const isModalOpen = ref(false);
const loading = ref(true);
const state = reactive({
  videos: [],
  limit: 16,
  firstVisible: '',
  lastVisible: '',
  pagesNumber: 1,
  currentPage: 1,
  previousBtn: false,
  nextBtn: true,
  currentCategory: 'all',
  selectedVideoId: '',
});

function scrollUp() {
  document.body.style.touchAction = 'auto';
  let href = '#portfolio';
  const y = document.querySelectorAll(href)[0].getBoundingClientRect().top + window.scrollY;
  window.scrollTo({
    top: y,
    behavior: 'smooth',
  });
}

function touchStart(e) {
  const element = e.target.parentNode;
  element.classList.add('hovered');
}

function touchEnd(e) {
  const element = e.target.parentNode;
  element.classList.remove('hovered');
}

async function filter(category) {
  loading.value = true;
  state.currentCategory = category;
  state.currentPage = 1;
  state.previousBtn = false;
  const colRef = collection(db, 'videos');
  const queryConstraints = [];
  if (category !== 'all') {
    queryConstraints.push(where('category', '==', category));
  }
  const querySnap = await getDocs(query(colRef, ...queryConstraints));
  state.pagesNumber = Math.ceil(querySnap.size / state.limit);
  queryConstraints.push(orderBy('youtubeId'));
  const q = query(colRef, ...queryConstraints, limit(state.limit));
  await getDocs(q)
    .then((doc) => {
      state.lastVisible = doc.docs[doc.docs.length - 1];
      state.firstVisible = doc.docs[0];
      state.videos = [];
      if (doc.size > 0) {
        doc.docs.forEach(async (doc) => {
          const video = await getVideoDetails(doc.data().youtubeId);
          state.videos.push(video);
        });
      }
    })
    .then(async () => {
      if (state.lastVisible) {
        queryConstraints.push(startAfter(state.lastVisible));
        const q = query(colRef, ...queryConstraints, limit(state.limit));
        await getDocs(q).then((snap) => {
          state.nextBtn = snap.size === 0 ? false : true;
        });
      } else {
        state.nextBtn = false;
      }
      loading.value = false;
    });
}

async function getVideoDetails(id) {
  const url = `https://youtube.com/oembed?url=https://www.youtube.com/watch?v=${id}&format=json`;
  const response = await fetch(url);
  const { title, thumbnail_url } = await response.json();
  thumbnail_url.replace(/\/[^\/]+$/, '/maxresdefault.jpg');
  const thumbnailResponse = await fetch(thumbnail_url, {
    mode: 'no-cors',
  });
  if (thumbnailResponse.status === 404) {
    thumbnail_url.replace('maxresdefault', 'hq3');
  }
  const video = {
    id: id,
    thumbnail: thumbnail_url,
    title: title,
    source: `https://www.youtube.com/embed/${id}`,
  };
  return video;
}

async function next() {
  if (state.nextBtn) {
    const colRef = collection(db, 'videos');
    let queryConstraints = [];
    if (state.currentCategory !== 'all') {
      queryConstraints.push(where('category', '==', state.currentCategory));
    }
    queryConstraints.push(orderBy('youtubeId'));
    queryConstraints.push(startAfter(state.lastVisible));
    queryConstraints.push(limit(state.limit));
    const q = query(colRef, ...queryConstraints);
    await getDocs(q)
      .then((doc) => {
        state.lastVisible = doc.docs[doc.docs.length - 1];
        state.firstVisible = doc.docs[0];
        state.videos = [];
        doc.docs.forEach(async (doc) => {
          const video = await getVideoDetails(doc.data().youtubeId);
          state.videos.push(video);
        });
        state.currentPage = state.currentPage + 1;
      })
      .then(async () => {
        queryConstraints = [...queryConstraints, startAfter(state.lastVisible)];
        const q = query(colRef, ...queryConstraints);
        await getDocs(q).then((snap) => {
          if (snap.size === 0) {
            state.nextBtn = false;
            state.previousBtn = true;
          } else {
            state.nextBtn = true;
            state.previousBtn = true;
          }
          scrollUp();
        });
      });
  }
}

async function previous() {
  const colRef = collection(db, 'videos');
  let queryConstraints = [];
  if (state.currentCategory !== 'all') {
    queryConstraints.push(where('category', '==', state.currentCategory));
  }
  queryConstraints.push(orderBy('youtubeId'));
  queryConstraints.push(endBefore(state.firstVisible));
  queryConstraints.push(limitToLast(state.limit));
  const q = query(colRef, ...queryConstraints);
  await getDocs(q)
    .then((snap) => {
      return snap.size;
    })
    .then(async (size) => {
      if (size !== 0) {
        const q = query(
          colRef,
          orderBy('youtubeId'),
          endBefore(state.firstVisible),
          limitToLast(state.limit)
        );
        await getDocs(q)
          .then((doc) => {
            state.lastVisible = doc.docs[doc.docs.length - 1];
            state.firstVisible = doc.docs[0];
            state.videos = [];
            doc.docs.forEach(async (doc) => {
              const video = await getVideoDetails(doc.data().youtubeId);
              state.videos.push(video);
            });
            state.currentPage = state.currentPage - 1;
          })
          .then(async () => {
            queryConstraints = [...queryConstraints, endBefore(state.firstVisible)];
            const q = query(colRef, ...queryConstraints);
            await getDocs(q).then((snap) => {
              if (snap.size === 0) {
                state.nextBtn = true;
                state.previousBtn = false;
              } else {
                state.nextBtn = true;
                state.previousBtn = true;
              }
              scrollUp();
            });
          });
      }
    });
}

onMounted(async () => {
  const colRef = collection(db, 'videos');
  const querySnap = await getDocs(query(colRef));

  const q = query(colRef, orderBy('youtubeId'), limit(state.limit));
  await getDocs(q)
    .then((doc) => {
      state.lastVisible = doc.docs[doc.docs.length - 1];
      state.firstVisible = doc.docs[0];
      doc.docs.forEach(async (doc) => {
        const video = await getVideoDetails(doc.data().youtubeId);
        state.videos.push(video);
      });
    })
    .then(async () => {
      const q = query(
        colRef,
        orderBy('youtubeId'),
        startAfter(state.lastVisible),
        limit(state.limit)
      );
      await getDocs(q).then((snap) => {
        if (snap.size === 0) {
          state.nextBtn = false;
        }
        state.pagesNumber = Math.ceil(querySnap.size / state.limit);
        loading.value = false;
      });
    });
});
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.125rem;

  .title {
    position: relative;
    width: 13.125rem;
    text-align: center;

    .heading {
      span:after {
        height: 2px;
        width: 30px;
        content: '';
        display: block;
        background-color: #fff;
        margin: 0px auto 0;
      }
    }

    // .heading:after {
    //   content: '';
    //   background: rgba(255, 255, 255, 0.4588235294);
    //   position: absolute;
    //   right: 0;
    //   height: 3.125rem;
    //   width: 0.063rem;
    //   top: 1.563rem;
    // }

    // .heading:before {
    //   content: '';
    //   background: rgba(255, 255, 255, 0.4588235294);
    //   position: absolute;
    //   left: 0;
    //   height: 3.125rem;
    //   width: 0.063rem;
    //   top: 1.563rem;
    // }
  }

  .categories {
    display: flex;
    list-style: none;
    overflow: hidden;
    flex-wrap: wrap;
    justify-content: center;

    li {
      padding: 0.625rem;
      margin-bottom: 0.125rem;

      button {
        font-family: 'Nunito Sans', sans-serif;
        box-shadow: 2.138rem 0.269rem 0.269rem hsl (0deg 0% 0% / 0.43);
        background: none;
        cursor: pointer;
        //color: #ffffff75;
        color: #ffffff75;
        display: inline-flex;
        width: 7.5rem;
        height: 2.125rem;
        font-weight: 700;
        font-size: 0.75rem;
        border: 0.063rem solid #f6b13070;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 0;
        background: transparent;
        outline: none;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        position: relative;
        transition: all 0.45s ease-Out;

        &.is-active {
          background: rgba(246, 177, 48, 0.22);
          color: #f6b130;
          box-shadow: none;
        }

        &:hover {
          background: rgba(246, 177, 48, 0.22);
          color: #f6b130;
        }
      }
    }
  }

  @media (hover: hover) {
    .gallery li {
      &:hover .overlay,
      &:hover img {
        transform: scale(1.3);
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3.125rem;
  }

  .gallery {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    overflow: hidden;
    column-count: 4;
    height: 100%;

    li {
      //height: 300px;
      position: relative;
      cursor: pointer;
      flex: 25%;
      -webkit-flex: 25%;
      overflow: hidden;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        vertical-align: middle;
        border-radius: 0.313rem;
        transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) !important;
      }

      .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(10, 0, 0, 0.5);
        font-size: 0.875rem;
        top: 0;
        left: 0;
        transform: scale(0);
        transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) !important;
        color: #fff;
        border-radius: 0.313rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3.75rem;
        text-align: center;
      }

      &.hovered .overlay,
      &.hovered img {
        transform: scale(1.3);
      }
    }
  }

  .pagination {
    display: flex;
    gap: 0.938rem;

    li {
      list-style: none;

      a:not(.is-active) {
        border: none;
        box-shadow: none;
      }
    }
    a {
      font-family: 'Nunito Sans', sans-serif;
      background: none;
      color: #ffffff75;
      display: inline-flex;
      width: 2.5rem;
      height: 2.125rem;
      font-weight: 700;
      font-size: 0.75rem;
      border: 0.063rem solid #f6b13070;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: 0;
      background: transparent;
      outline: none;
      box-shadow: 2.138rem 0.269rem 0.269rem hsl (0deg 0% 0% / 0.43);
      overflow: hidden;
      align-items: center;
      justify-content: center;
      position: relative;
      transition: all 0.45s ease-Out;
      user-select: none;

      &:not(.page) {
        cursor: pointer;
      }

      &.is-active {
        background: rgba(246, 177, 48, 0.22);
        color: #f6b130;
        box-shadow: none;
      }

      &:not(.page):hover {
        background: rgba(246, 177, 48, 0.22);
        color: #f6b130;
      }
    }
  }
}

@media (max-width: 1024px) {
  .container {
    .gallery {
      li {
        flex: 50%;
      }
    }
  }
}

@media (max-width: 768px) {
  .container {
    .title {
      width: 9.375rem;

      .heading:after {
        height: 1.875rem;
        top: 2rem;
      }

      .heading:before {
        height: 1.875rem;
        top: 2rem;
      }
    }
    .categories {
      li {
        button {
          font-size: 0.625rem;
          width: 5.625rem;
        }
      }
    }
    .gallery {
      padding: 0 3.75rem;
      li {
        width: 9.375rem;
        flex: 100%;
        .overlay {
          padding: 2.5rem;
          span {
            font-size: 0.625rem;
          }
        }
      }
    }
    .pagination {
      li {
        a {
          font-size: 0.625rem;
        }
      }
    }
  }
}
</style>
