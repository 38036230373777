<template>
  <div class="container">
    <div class="title">
      <h1 class="heading"><span>Rental</span></h1>
    </div>
    <ul class="rentals">
      <li v-for="item in rentalItems" :key="item">
        <rental-item :title="item.title" :image="item.image" :price="item.price"></rental-item>
      </li>
    </ul>
  </div>
</template>

<script setup>
import RentalItem from './RentalItem.vue';

const rentalItems = [
  {
    title: 'Tilta Nucleus-M',
    image: new URL(`../assets/rental/nucleus m.jpg`, import.meta.url).href,
    price: '€60',
  },
  {
    title: 'Kit RED DSMC2 GEMINI 5K S35',
    image: new URL(`../assets/rental/red.jpg`, import.meta.url).href,
    price: '€350',
  },
  {
    title: 'SONY FX3',
    image: new URL(`../assets/rental/fx3.jpg`, import.meta.url).href,
    price: '€80€',
  },
  {
    title: 'Samyang VDSLR - Canon FE 6-Lens Kit',
    image: new URL(`../assets/rental/samyang.jpg`, import.meta.url).href,
    price: '€60',
  },
  {
    title: 'SIGMA 24-70 SONY E MOUNT',
    image: new URL(`../assets/rental/sigma.jpg`, import.meta.url).href,
    price: '€30',
  },
  {
    title: 'Tilta Mirage Matte Box',
    image: new URL(`../assets/rental/mirage.jpg`, import.meta.url).href,
    price: '€30',
  },
  {
    title: 'TERADEK BOLT 1000 WITH SMALLHD ULTRABRIGHT MONITOR',
    image: new URL(`../assets/rental/teradek.jpg`, import.meta.url).href,
    price: '€50',
  },
  {
    title: 'ATOMOS SUMO 19',
    image: new URL(`../assets/rental/sumo.jpg`, import.meta.url).href,
    price: '€60',
  },
  {
    title: 'ATOMOS SHOGUN INFERNO 7 INCH',
    image: new URL(`../assets/rental/sogun m.jpg`, import.meta.url).href,
    price: '€40',
  },
];
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.125rem;

  .title {
    position: relative;
    width: 10rem;
    text-align: center;

    .heading {
      span {
        color: black;
      }
      span:after {
        height: 2px;
        width: 30px;
        content: '';
        display: block;
        background-color: black;
        margin: 0px auto 0;
      }
    }
  }

  .rentals {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    overflow: hidden;
    max-width: 80vw !important;

    li {
      position: relative;
      display: flex;
      justify-content: center;
      flex: 33.33%;
      -webkit-flex: 33.33%;
      overflow: hidden;
      padding: 30px;
    }
  }
}

@media (max-width: 1024px) {
  .container {
    .rentals {
      li {
        flex: 50%;
      }
    }
  }
}

@media (max-width: 768px) {
  .container {
    gap: 1.875rem;
    .title {
      width: 9.375rem;

      .heading:after {
        height: 1.875rem;
        top: 2rem;
      }

      .heading:before {
        height: 1.875rem;
        top: 2rem;
      }
    }
    .rentals {
      li {
        flex: 100%;
      }
    }
  }
}
</style>
