import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCWSjq9TDVKqtshQF95kmprBRrXap-rR4Q',
  authDomain: 'alibportfolio.firebaseapp.com',
  projectId: 'alibportfolio',
  storageBucket: 'alibportfolio.appspot.com',
  messagingSenderId: '491061053880',
  appId: '1:491061053880:web:31a9f7e97298b27ca2674d',
};
// init firebase
initializeApp(firebaseConfig);

// init firestore service
const db = getFirestore();
export default db;
