<template>
  <div class="home">
    <section id="home" data-index="1">
      <div class="container reveal">
        <div class="home-title">
          <h6 class="title">I AM ALIB</h6>
          <VueWriter
            :array="['DIRECTOR', 'CINEMATOGRAPHER', 'FILMMAKER']"
            :typeSpeed="100"
            :eraseSpeed="50"
          />
        </div>
      </div>
    </section>
    <section id="portfolio" data-index="2">
      <div class="container reveal"><portfolio /></div>
    </section>
    <section id="rental" data-index="3">
      <div class="container reveal"><rental /></div>
    </section>
    <section id="about" data-index="4">
      <div class="container reveal">
        <about />
      </div>
    </section>
    <!-- <div class="brands">
      <h3>Brands I worked with.</h3>
      <Carousel :settings="settings" :breakpoints="breakpoints">
        <Slide v-for="brand in brands" :key="brand">
          <img :src="brand" width="200" alt="" />
        </Slide>
        <template #addons>
          <Pagination />
        </template>
      </Carousel>
    </div> -->
    <!-- <div class="profile">
      <img src="~@/assets/cover4.jpeg" />
    </div> -->
    <section id="contact" data-index="5">
      <div class="container reveal"><contact /></div>
    </section>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import Portfolio from '@/components/Portfolio.vue';
import Rental from '@/components/Rental.vue';
import About from '@/components/About.vue';
import Contact from '@/components/Contact.vue';
import VueWriter from 'vue-writer';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

const store = useStore();

const brands = [
  new URL(`../assets/brands/cat.jpg`, import.meta.url).href,
  new URL(`../assets/brands/demoga.png`, import.meta.url).href,
  new URL(`../assets/brands/lioness.png`, import.meta.url).href,
  new URL(`../assets/brands/sprint.png`, import.meta.url).href,
  new URL(`../assets/brands/tru.png`, import.meta.url).href,
];

const settings = {
  itemsToShow: 3,
  snapAlign: 'center',
  autoplay: 2000,
  wrapAround: true,
};

const breakpoints = {
  1024: {
    itemsToShow: 5,
    snapAlign: 'center',
    autoplay: 0,
    wrapAround: false,
  },
};

onMounted(() => {
  store.commit('setHomeRendered', true);
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          let section = entry.target.getAttribute('id');
          store.commit('setSection', section);
          entry.target.childNodes[0].classList.add('active');
          if (section === 'contact') {
            document.querySelectorAll('.contact-right')[0].style.display = 'none';
          } else {
            document.querySelectorAll('.contact-right')[0].style.display = 'inline-block';
          }
        }
      });
    },
    { rootMargin: '-400px 0px 0px 0px', threshold: window.innerHeight > 1000 ? 0.25 : 0.1 }
  );
  const targets = document.querySelectorAll('section');
  targets.forEach((target) => {
    observer.observe(target);
  });
});
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  position: relative;
}
.brands {
  background: #fff;
  width: 100vw;

  img {
    //object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
    height: 100px;
    width: 100px;
  }
}
.home-title {
  position: absolute;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 3.125rem;
  margin-top: 6.25rem;

  .title {
    font-family: 'Hind Siliguri';
    font-size: 16px;
    color: black;
    font-weight: 700;
    background: #fff;
    width: fit-content;
    padding: 2px 5px;
    line-height: 25px;
  }
}
.profile {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  border: 0.625rem solid #1d1d1d;
  padding: 0.938rem;
  z-index: 99;
  background: #1d1d1d;

  img {
    width: 9.375rem;
    height: 12.5rem;
  }
}
.content {
  max-width: 800px;
}
section:not(.carousel) {
  width: 100vw;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  min-height: 100vh;
  padding: 3.125rem 0;

  .container {
    padding-bottom: 1.25rem;
    width: 100%;
  }

  &:not(#home, #rental, #about, #contact):after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0.063rem;
    background: hsla(0, 0%, 100%, 0.12);
  }
}
#home {
  background: radial-gradient(at 50% 0, #151515, transparent 20%),
    // radial-gradient(at 0% 0, #151515, transparent 100%),
    linear-gradient(90deg, #151515, transparent 50%) right,
    linear-gradient(-90deg, #151515, transparent 50%) right,
    linear-gradient(0deg, #151515, transparent 50%) bottom, url('~@/assets/cover.JPG');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
#about {
  background: radial-gradient(at 50% 0, #151515, transparent 70%),
    // radial-gradient(at 0% 0, #151515, transparent 100%),
    linear-gradient(90deg, #151515, transparent 50%) right,
    linear-gradient(-90deg, #151515, transparent 50%) right,
    linear-gradient(0deg, #151515, transparent 50%) bottom, url('~@/assets/cover3.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
#contact {
  height: 100% !important;
  min-height: 100% !important;
  padding: 0 !important;
}
#rental {
  background: #fff !important;
  max-width: 100vw !important;
}
h1 {
  font-size: 9.375rem;
}

.reveal {
  position: relative;
  transform: translateY(9.375rem);
  opacity: 0;
  transition: 1s all ease;

  &.active {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (min-width: 768px) {
  .home {
    .title {
      font-size: 24px;
      margin-bottom: 10px;
    }
    #home {
      margin: 0 auto;
      max-width: 90vw;
    }
    section:not(#home) {
      margin: 0 auto;
      max-width: 70vw;
    }
    #portfolio {
      max-width: 90vw !important;
    }
  }
  .profile {
    img {
      width: 18.75rem;
      height: 25rem;
    }
  }
}

@media (max-width: 1780px) {
  #contact {
    max-width: 100vw !important;
  }
}

@media (min-width: 1660px) {
  .home-title {
    left: 6.25rem;
    top: 6.25rem;
    margin-top: 25rem;
    justify-content: normal;
    align-items: normal;
  }
  #home {
    background-position-y: 35%;
  }
}
</style>
