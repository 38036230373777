<template>
  <div>
    <div class="contact-container">
      <div class="column">
        <p>CONTACT</p>
        <h3>Get in touch with me.</h3>
      </div>
      <div class="column">
        <FormKit type="form" id="form" submit-label="Send" @submit="send" class="form">
          <FormKit
            label="Name"
            name="name"
            placeholder="Name"
            type="text"
            inner-class="form-input"
            v-model="state.name"
            validation="required"
            validation-visibility="submit"
          />
          <FormKit
            label="Email"
            name="email"
            placeholder="Email"
            type="text"
            validation="required|email"
            validation-visibility="submit"
            inner-class="form-input"
            v-model="state.email"
          />
          <FormKit
            type="textarea"
            label="Message"
            name="message"
            rows="2"
            placeholder="Message"
            inner-class="form-input"
            validation="required"
            validation-visibility="submit"
            v-model="state.message"
          />

          <!-- <button @click.prevent="send" type="submit">Send</button> -->
        </FormKit>
        <div class="success" v-if="submitted">
          <div id="tick-mark"></div>
          <span>The form was sent successfully.</span>
        </div>
      </div>
    </div>
    <div class="contact-bottom">
      <div class="info">
        <h3>ALIB</h3>
        <span>Director, Cinematographer & Editor.</span>
        <div class="social">
          <a href="tel:0766294429"><font-awesome-icon icon="phone" /></a>
          <a href="mailto:alib.studio1@gmail.com">
            <font-awesome-icon icon="envelope" />
          </a>
          <a href="https://www.facebook.com/AlinAliB" target="_blank">
            <font-awesome-icon icon="fa-brands fa-facebook" />
          </a>
          <a href="https://www.instagram.com/alinalib" target="_blank">
            <font-awesome-icon icon="fa-brands fa-instagram" />
          </a>
        </div>
      </div>
      <div class="copyright">
        <p>Copyright @ 2022 Andrei Bratu</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue';
import emailjs from 'emailjs-com';
import { reset } from '@formkit/core';

const submitted = ref(false);
const state = reactive({
  name: '',
  email: '',
  message: '',
});

function send(e) {
  try {
    emailjs.sendForm(
      'service_4yac1r9',
      'template_jaqq86d',
      e.target.parentElement,
      '1nqsp5pw4_gnbMnTl'
    );
    submitted.value = true;
    reset('form');
  } catch (error) {
    console.log({ error });
  }
  // Reset form field
  state.name = '';
  state.email = '';
  state.message = '';
}
</script>

<style lang="scss">
.contact-container {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  //height: 450px;
  padding: 100px;
  flex-wrap: wrap;

  .column {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    font-family: 'Hind Siliguri';

    .success {
      margin-top: 10px;
      display: flex;
      gap: 10px;
      #tick-mark {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
      }

      #tick-mark::before {
        position: absolute;
        left: 0;
        top: 50%;
        height: 50%;
        width: 3px;
        background-color: green;
        content: '';
        transform: translateX(10px) rotate(-45deg);
        transform-origin: left bottom;
      }

      #tick-mark::after {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 3px;
        width: 100%;
        background-color: green;
        content: '';
        transform: translateX(10px) rotate(-45deg);
        transform-origin: left bottom;
      }
    }

    &:nth-child(2) {
      border-style: solid;
      border-width: 0 0 0 1px;
      border-color: #ffffff30;
      padding: 20px 0 20px 50px;
    }

    .formkit-form {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .formkit-message {
        color: #ea0000 !important;
        font-size: 0.8125em;
      }
      .formkit-inner {
        input {
          height: 20px;
        }
        input,
        textarea {
          font-family: hind siliguri, Sans-serif;
          font-size: 16px;
          font-weight: 300;
          width: 100%;
          padding: 30px 30px 20px;
        }
      }

      button {
        color: #fff;
        flex-basis: 100%;
        width: 100%;
        min-height: 40px;
        cursor: pointer;
        font-family: hind siliguri, Sans-serif;
        font-size: 15px;
        display: inline-block;
        text-align: center;
        white-space: nowrap;
        user-select: none;
        font-weight: 600;
        background-color: #02010100;
        border: 1px solid #fff;
        border-radius: 0 0 0 0;
        padding: 20px 30px;
        transition: all 0.3s;

        &:hover {
          background: rgba(246, 177, 48, 0.22);
        }

        &:active {
          transform: scale(0.8, 0.8);
        }

        @keyframes press {
          0% {
            transform: scale(1);
          }
          50% {
            transform: scale(0.92);
          }
          to {
            transform: scale(1);
          }
        }
      }
    }

    p {
      font-size: 17px;
    }

    h3 {
      font-size: 55px;
    }
  }
}

.contact-bottom {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;

  .info {
    margin-top: 0px;
    margin-bottom: 50px;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .social {
      display: flex;
      margin-top: 30px;
      gap: 30px;

      a {
        border-style: solid;
        border-width: 1px 1px 1px 1px;
        border-color: #ffffff30;
        border-radius: 50%;
        padding: 0.6em;
        color: #f6b130;
        width: 40px;
        height: 40px;
        font-size: 16px;
        text-align: center;

        &:hover {
          border-color: #fff;
        }
      }
    }

    h3 {
      color: #fff;
      font-family: 'Hind Siliguri';
      font-size: 30px;
    }

    span {
      color: rgba(255, 255, 255, 0.4588235294);
      font-size: 16px;
    }
  }
}

.copyright {
  padding: 20px 0px 0px 0px;
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  border-color: #ffffff30;
  display: flex;
  justify-content: center;
  color: #ececec;
  font-size: 16px;
  font-weight: 300;
  font-family: 'Hind Siliguri';
}

@media (max-width: 768px) {
  .contact-container {
    padding: 100px 20px;
    .column {
      width: 100%;
      padding: 0 20px !important;

      .formkit-form {
        button {
          min-height: 10px;
        }
      }

      h3 {
        font-size: 35px;
      }

      &:nth-child(2) {
        border: none;
      }
    }
  }
  .contact-bottom {
    .info {
      h3 {
        font-size: 26px;
      }

      .social {
        a {
          padding: 0.5em;
        }
      }
    }
    .copyright {
      font-size: 14px;
    }
  }
}
</style>
