<template>
  <header>
    <teleport to="body">
      <div class="contact-right">
        <ul>
          <li>CONTACT</li>
          <li>
            <a href="tel:0766294429"><font-awesome-icon icon="phone" /></a>
          </li>
          <li>
            <a href="mailto:alib.studio1@gmail.com">
              <font-awesome-icon icon="envelope" />
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/AlinAliB" target="_blank">
              <font-awesome-icon icon="fa-brands fa-facebook-f" />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/alinalib" target="_blank">
              <font-awesome-icon icon="fa-brands fa-instagram" />
            </a>
          </li>
        </ul>
      </div>
    </teleport>
    <div class="container">
      <a href="#home" class="logo" @click.prevent="scrollTo($event)">
        <img src="~@/assets/logo.png" alt="" />
      </a>

      <nav class="site-nav" id="navbar" :class="{ 'is-active': isMenuOpen }">
        <ul>
          <li v-for="section in sections" :key="section">
            <a
              :href="section.route"
              :id="section.alias"
              class="btn"
              :class="{ 'is-active': section.alias.toLowerCase().includes(currentSection) }"
              @click.prevent="scrollTo($event)"
              @touchstart="touchStart($event)"
            >
              <div id="translate"></div>
              <span>{{ section.alias }}</span></a
            >
          </li>
        </ul>
      </nav>

      <button
        type="button"
        class="hamburger"
        :class="{ 'is-active': isMenuOpen }"
        @click="toggleMenu"
      >
        <span />
        <span />
        <span />
      </button>
    </div>
  </header>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const currentSection = computed(() => store.state.currentSection);
const homeRendered = computed(() => store.state.homeRendered);
const isMenuOpen = ref(false);
const sections = [
  {
    route: '#home',
    alias: 'Home',
  },
  {
    route: '#portfolio',
    alias: 'Portfolio',
  },
  {
    route: '#rental',
    alias: 'Rental',
  },
  {
    route: '#about',
    alias: 'About me',
  },
  {
    route: '#contact',
    alias: 'Contact',
  },
];

watch(() => {
  if (homeRendered.value) {
    window.onscroll = function () {
      scrollFunction();
    };

    function scrollFunction() {
      const header = document.querySelector('header');
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        header.classList.add('nav-scrolled');
      } else {
        header.classList.remove('nav-scrolled');
      }
    }
  }
});

function toggleMenu() {
  isMenuOpen.value = !isMenuOpen.value;
  document.body.style.touchAction = isMenuOpen.value ? 'none' : 'auto';
}

function scrollTo(e) {
  document.body.style.touchAction = 'auto';
  isMenuOpen.value = false;
  let href = e.target.getAttribute('href');
  if (!href) {
    href = e.target.parentNode.getAttribute('href');
  }
  const y = document.querySelectorAll(href)[0].getBoundingClientRect().top + window.scrollY;
  seamless.scrollIntoView(document.querySelector(href), {
    behavior: 'smooth',
    block: 'center',
    inline: 'center',
  });
}
</script>

<style lang="scss" scoped>
.container {
  margin: 0 auto;
}

.contact-right {
  vertical-align: baseline;
  position: fixed;
  right: 0;
  bottom: 1.25rem;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none outside;

    li:first-child {
      display: none;
      color: #a1a1a1;
      transform: rotate(90deg);
      margin-bottom: 3.125rem;
    }

    li {
      width: 100%;
      text-align: center;
      margin: 1rem 0;

      a {
        text-decoration: none;
        color: #f6b130;
        outline: none;
      }
    }
  }
}

header {
  position: fixed;
  width: 100%;
  height: 3.438rem;
  background: transparent;
  padding: 1rem 2rem;
  z-index: 999 !important;
  transition: all 500ms cubic-bezier(0.445, 0.05, 0.55, 0.95);

  &::after {
    content: '';
    clear: both;
    display: block;
  }
}

.nav-scrolled {
  background: rgba(8, 8, 8, 0.9);
  box-shadow: 0 0 transparent, 0 0 transparent, 0 0.313rem 0.313rem -0.25rem rgb(8 8 8 / 90%);
}

.logo {
  clear: both;
  cursor: pointer;
  img {
    width: 7.5rem;
    height: 7.5rem;
    overflow: hidden;
    position: absolute;
    top: -1.875rem;
    left: 20;
  }
}

.site-nav {
  position: fixed;
  top: 0;
  height: 100vh;
  right: 0%;
  left: 0;
  background: rgba(8, 8, 8, 1);
  clip-path: circle(0px at top right);
  transition: clip-path ease-in-out 500ms;
  display: none;
  justify-content: center;
  align-items: flex-start;

  &.is-active {
    clip-path: circle(250% at top right);
    display: flex;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 50vw;
    margin-top: 5rem;
  }

  li {
    border-bottom: 0.063rem solid #575766;
    padding: 2.5rem 0;
    display: flex;
    justify-content: center;

    &:last-child {
      border-bottom: none;
    }
  }

  .btn {
    color: #ffffff75;
    display: inline-flex;
    width: 8.75rem;
    height: 100%;
    font-weight: 700;
    font-size: 0.875rem;
    padding: 0.5rem 1.5rem;
    border: 0.063rem solid #f6b13070;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0;
    background: transparent;
    outline: none;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    position: relative;

    &#Portfolio {
      background: rgba(246, 177, 48, 0.22);
    }

    span {
      position: relative;
      transition: all 0.45s ease-Out;
    }

    #translate {
      transform: rotate(50deg);
      width: 100%;
      height: 250%;
      left: -12.5rem;
      top: -22x;
      background: rgba(246, 177, 48, 0.22);
      position: absolute;
      transition: all 0.3s ease-in;
    }

    &.is-active #translate,
    &:hover #translate {
      left: 0;
    }

    &.is-acitve {
      box-shadow: none;
    }

    &.is-active,
    &:hover {
      color: #f6b130;
    }
  }
}

.hamburger {
  display: block;
  position: absolute;
  right: 1.5em;
  top: 1.8em;
  z-index: 1;
  user-select: none;
  appearance: none;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;

  span {
    display: block;
    width: 1.875rem;
    height: 0.125rem;
    margin-bottom: 0.313rem;
    position: relative;
    background-color: #eeeeee;
    border-radius: 0.625rem;
    z-index: 1;
    transform-origin: 0 0;
    transition: 0.4s;
  }
}

.hamburger.is-active span:nth-child(1) {
  transform: translate(0px, -0.125rem) rotate(45deg);
}

.hamburger.is-active span:nth-child(2) {
  opacity: 0;
  transform: translateX(0.938rem);
}

.hamburger.is-active span:nth-child(3) {
  transform: translate(-0.188rem, 0.313rem) rotate(-45deg);
}

@media (min-width: 1024px) {
  .contact-right {
    bottom: 5rem;
    ul {
      li:first-child {
        display: block;
      }
    }
  }
  header {
    height: 4.125rem;
  }
  .hamburger {
    display: none;
  }
  .site-nav {
    height: auto;
    position: relative;
    background: transparent;
    float: right;
    clip-path: initial;
    touch-action: none;
    display: block;

    ul {
      width: auto;
      display: inline;
    }

    li {
      display: inline-block;
      border: none;
      padding: 0 0.625rem;
    }

    .btn {
      width: 7.5rem;
      height: 100%;
      font-weight: 700;
      font-size: 0.75rem;
    }
  }
  .logo {
    img {
      width: 11.875rem;
      height: 11.875rem;
      top: -3.75rem;
    }
  }
}
</style>
