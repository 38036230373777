<template>
  <div class="container">
    <div class="title">
      <h1 class="heading"><span>About me</span></h1>
    </div>
    <div class="tabs">
      <div class="tab">
        <input type="radio" id="tab-1" name="tabs" checked />
        <label for="tab-1">EN</label>
        <div class="content">
          <p>
            After 15 years as a dancer for many Romanian artists, I decided to change my profession.
            Totally by chance, I chose to do videography, and with that, I found a new passion that
            became a lifestyle. It was always difficult for me to express myself, but dance and
            videography were the ways I managed to do it and give free rein to my creativity.
          </p>
          <div></div>
          <p>
            The displayed nonconformist attitude hides a sensitive and creative man named Alin. I,
            Alin, am a young man with a dream come true who makes every project something unique. As
            a great asset, I can say that I try to fulfill the clients' requirements, listening to
            their ideas and at the same time offering them my innovative vision.
          </p>
          <div></div>
          <p>
            Being among the youngest directors in the field, I am open to everything that means new
            and original so that, at the end of the day, the project is a success and brings
            satisfaction among the clients.
          </p>
        </div>
      </div>
      <div class="tab">
        <input type="radio" id="tab-2" name="tabs" />
        <label for="tab-2">RO</label>
        <div class="content">
          <p>
            Dupa 15 ani in calitate de dansator al multor artisti ai Romaniei, am hotarat sa-mi
            schimb profesia. Total intamplator, am ales sa fac videografie, iar odata cu asta, mi-am
            gasit o noua pasiune care a devenit intre timp si un stil de viata. Mi-a fost
            intotdeauna greu sa ma exprim, insa dansul si videografia au fost modalitatile prin care
            am reusit sa o fac si sa-mi dau frau liber creativitatii.
          </p>
          <div></div>
          <p>
            Atitudinea nonconformista afisata ascunde un om sensibil si creativ pe nume Alin. Eu,
            Alin, sunt un tanar cu un vis devenit realitate care face din orice proiect ceva inedit.
            Ca un mare atuu, pot spune ca incerc sa indeplinesc cerintele clientilor, ascultandu-le
            ideile si oferindu-le totodata si viziunea mea inovativa.
          </p>
          <div></div>
          <p>
            Fiind printre cei mai tineri regizori din domeniu, am deschidere la tot ceea ce inseamna
            nou si inedit astfel incat, la finalul zilei, proiectul sa fie unul reusit si sa aduca
            multumire in randul clientilor.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.125rem;

  .title {
    position: relative;
    width: 13.125rem;
    text-align: center;

    .heading {
      span:after {
        height: 2px;
        width: 30px;
        content: '';
        display: block;
        background-color: #fff;
        margin: 0px auto 0;
      }
    }

    // .heading:after {
    //   content: '';
    //   background: rgba(255, 255, 255, 0.4588235294);
    //   position: absolute;
    //   right: 0;
    //   height: 3.125rem;
    //   width: 0.063rem;
    //   top: 1.563rem;
    // }

    // .heading:before {
    //   content: '';
    //   background: rgba(255, 255, 255, 0.4588235294);
    //   position: absolute;
    //   left: 0;
    //   height: 3.125rem;
    //   width: 0.063rem;
    //   top: 1.563rem;
    // }
  }

  div:empty {
    height: 1.25rem;
  }

  .tabs {
    position: relative;
    width: 80%;
    display: flex;
    border-radius: 0.5rem;
    background: #1d1d1d;
    margin-top: 50px;

    .tab {
      flex: 1;

      label {
        display: block;
        box-shadow: 1px 0.269rem 0.269rem hsl(0deg 0% 0% / 0.43);
        z-index: 10;
        /* tab content must clear this */
        height: 2.5rem;
        padding: 0.625rem;
        text-align: center;
        font-size: 20px;
        background: transparent;
        cursor: pointer;
        transition: background 0.5s ease;
        color: rgba(255, 255, 255, 0.4588235294);
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

        &:hover {
          background: rgba(246, 177, 48, 0.22);
          color: #f6b130;
        }
      }

      .content {
        position: absolute;
        font-weight: normal;
        font-size: 20px;
        z-index: -1;
        left: 0;
        right: 0;
        top: 2.5rem;
        line-height: 1.95rem;
        padding: 1.875rem;
        border-radius: 0 0 0.5rem 0.5rem;
        transition: opacity 0.8s ease, transform 0.8s ease;
        color: #fff;
        opacity: 0;
        transform: scale(0.1);
        background: rgba(8, 8, 8, 0.5);
        box-shadow: 0 0 transparent, 0 0 transparent, 0 0.313rem 0.313rem -0.25rem rgb(8 8 8 / 90%);
        transform-origin: top left;
      }
    }

    .tab [type='radio'] {
      display: none;
    }
    [type='radio']:checked ~ label {
      background: rgba(246, 177, 48, 0.22);
      color: #f6b130;
      box-shadow: none;
      z-index: 2;
    }

    [type='radio']:checked ~ label ~ .content {
      /* show/hide */
      opacity: 1;
      transform: scale(1);
    }
  }
}

@media (max-width: 768px) {
  .container {
    gap: 1.875rem;
    .title {
      width: 9.375rem;

      .heading:after {
        height: 1.875rem;
        top: 2rem;
      }

      .heading:before {
        height: 1.875rem;
        top: 2rem;
      }
    }
    .tabs {
      width: 90vw;

      .tab {
        label {
          font-size: 1rem;
        }
        .content {
          line-height: 1.125rem;
          p {
            font-size: 0.925rem;
          }
        }
      }
    }
  }
}
</style>
