<template>
  <div>
    <Teleport to="body">
      <div class="modal-overlay" id="modal-overlay" @click="emit('close')">
        <div class="modal" id="modal">
          <spinner v-if="loading" />
          <iframe
            :src="`https://www.youtube.com/embed/${videoId}`"
            loading="lazy"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;
        picture-in-picture"
            allowfullscreen
            @load="loading = false"
          ></iframe>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import Spinner from './Spinner.vue';

const emit = defineEmits(['close']);
const props = defineProps({
  videoId: String,
});
const loading = ref(true);

onMounted(() => {
  document.addEventListener('keyup', function (evt) {
    if (evt.keyCode === 27) {
      emit('close');
    }
  });
});
</script>

<style lang="scss" scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.9);
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 43.75rem;
  max-width: 100%;
  height: 25rem;
  max-height: 100%;
  position: fixed;
  z-index: 100;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 3.75rem 0.625rem rgba(0, 0, 0, 0.9);

  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
